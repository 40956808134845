import { TransformTag } from '@/utils'

export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.main', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  return [
    {
      condition: get('media.cover.length'),
      component: 'Section',
      props: {
        class: 'hero',
        width: 'full',

        style: 'padding:0;',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            data: get('media.cover'),
            props: {
              aspectRatio: '16/6.5',
              class: 'hero__slider',
              options: {
                manualNavigation: false,
              },
            },
            blocks: {
              default: [
                {
                  component: 'Flex',
                  props: {
                    class: 'hero__layout',
                  },
                  blocks: {
                    default: {
                      component: 'Text',
                      props: {
                        class: 'hero__content',
                      },
                      data: {
                        content: `<h1>${get('title')}</h1>`,
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        class: get('content') ? 'contact-section' : 'contact-section-empty',
      },
      blocks: {
        default: {
          component: 'Text',
          props: {
            textAlign: 'center',
          },
          data: {
            content: TransformTag(get('content')),
          },
        },
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        style:
          '--section-padding-inline: 0rem; --section-width: 1200px; padding-block: clamp(2rem, 6vw, 6rem) calc(3.14502% + 10px)',
      },
      blocks: {
        default: [
          {
            component: 'Image',
            props: {
              class: 'contact-icon',
            },
            data: {
              image: get('customFields.contact-information.icon'),
            },
          },
          {
            component: 'Text',
            props: {
              class: 'contact-content',
            },
            data: {
              content: get('customFields.contact-information.content'),
            },
          },

          {
            component: 'ContactList',
            props: {
              contactDetailStyle: 'icon',
            },
            data: {
              contacts: (get('customFields.contacts') || []).map(contact => {
                return {
                  ...contact,
                  avatar: contact.avatar.url,
                }
              }),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        class: 'press-room',
        width: 'medium',
        style: '--section-padding-inline: 0rem; --section-width: 1200px; padding-block: 0rem;',
      },
      blocks: {
        default: [
          {
            component: 'Image',
            props: {
              class: 'press-room__image',
            },
            data: {
              image: get('customFields.press-room.image'),
            },
          },
          {
            component: 'Flex',
            props: {
              class: 'press-room__overlay',
            },
            blocks: {
              default: {
                component: 'Text',
                props: {
                  class: 'press-room__content',
                },
                data: {
                  content: get('customFields.press-room.content'),
                },
              },
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        style: '--section-padding-inline: 0rem; --section-width: 1200px;',
        class: 'contact-section-2',
      },
      blocks: {
        default: [
          {
            component: 'Image',
            props: {
              class: 'contact-icon contact-icon--big',
            },
            data: {
              image: get('customFields.additional-contact-information.icon'),
            },
          },
          {
            component: 'Text',
            props: {
              class: 'contact-content',
            },
            data: {
              content: get('customFields.additional-contact-information.content'),
            },
          },

          {
            component: 'ContactList',
            props: {
              contactDetailStyle: 'icon',
              style: 'justify-content: center',
            },
            data: {
              contacts: (get('customFields.additional-contacts') || []).map(contact => {
                return {
                  ...contact,
                  avatar: contact.avatar.url,
                }
              }),
            },
          },
        ],
      },
    },

    {
      component: 'Banner',
      condition: get('customFields.contact-cta.content', 'Root.item'),
      props: {
        class: 'contact-cta-bottom ',
        style: 'padding-block: 3.5rem;',
      },
      data: {
        backgroundImage: get('customFields.contact-cta.image.url', 'Root.item'),
      },

      blocks: {
        default: {
          component: 'Text',
          data: {
            content: get('customFields.contact-cta.content', 'Root.item'),
          },
          props: {
            textAlign: 'center',
          },
        },
      },
    },
  ]
}
