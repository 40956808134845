import { TransformTag } from '@/utils'

export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.main', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  return [
    {
      condition: get('media.cover.length'),
      component: 'Section',
      props: {
        class: 'hero',

        width: 'full',
        style: 'padding:0;',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            data: get('media.cover'),
            props: {
              aspectRatio: '16/6.5',
              class: 'hero__slider',
              options: {
                manualNavigation: false,
              },
            },
            blocks: {
              default: [
                {
                  component: 'Flex',
                  props: {
                    class: 'hero__layout',
                  },
                  blocks: {
                    default: {
                      component: 'Text',
                      props: {
                        class: 'hero__content',
                      },
                      data: {
                        content: `<h1>${get('title')}</h1>`,
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...(get('customFields.additional-sections') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          showResponsivePadding: true,
          gap: '2rem',
        },
      }
    }),

    {
      condition: () => {
        return (get('customFields.icons') || []).filter(i => {
          if (!i) return false
          return i.content || i.icon
        }).length
      },
      component: 'Section',
      props: {
        width: 'medium',
        theme: get('customFields.icons-settings.theme') || 'none',
        class: 'features',
      },

      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              class: 'features__gap',
              columns: parseInt(get('customFields.icons-settings.columns')) || 4,
              iconBackgroundColor: get('customFields.icons-settings.show-icon-background-color')
                ? get('customFields.icons-settings.icon-background-color')
                : 'inherit',

              borderTheme: get('customFields.icons-settings.border-theme'),
              placement: get('customFields.icons-settings.placement'),
              padding: '0',
            },
            data: {
              content: get('customFields.icons'),
            },
            variant: get('customFields.icons-settings.variant') || 'none',
          },
        ],
      },
    },
    ...(get('customFields.additional-sections-1') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          class: '',
          showResponsivePadding: true,
        },
      }
    }),

    {
      component: 'Section',
      condition: get('customFields.lead.display-lead'),
      props: {
        class: 'about-subpage-lead-section',
        width: 'small',
        style: '--section-width: 640px;',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              textAlign: 'center',
              class: 'residential-lead-content',
              style: 'padding-bottom: 2rem',
            },
            data: {
              content: get('customFields.lead-content'),
            },
          },

          {
            component: 'Lead',
            props: {
              class: 'about-subpage-lead',
              options: {
                mergeDataToComment: ['title', 'category'],
                defaultData: {
                  email: 'innspill_kontakt@dummy.no',
                  name: 'Innspill',
                  privacy: true,
                },
                fields: ['!title', '!upsell', '!email', '!name', '!phone', '!privacy', '!comment'],
                actionLabel: this.$t('send'),

                //add fields for custom check-box
                formFields: [
                  {
                    order: 10,
                    key: 'title',
                    component: 'Input',
                    label: 'Emne',
                    options: {
                      size: '2',
                      validation: 'required',
                      props: {
                        placeholder: 'Emne',
                        items: (get('customFields.lead.category') || []).map(i => {
                          return {
                            title: i,
                            id: i,
                          }
                        }),
                      },
                    },
                  },
                  {
                    key: 'comment',
                    component: 'TextArea',
                    label: 'Beskjed',
                    options: {
                      validation: 'required',
                      size: '2',
                      props: {
                        placeholder: 'Beskjed',
                      },
                    },
                  },
                  {
                    order: 50,
                    key: 'category',
                    component: 'SelectList',
                    label: get('customFields.lead.category-title'),
                    options: {
                      size: '2',
                      validation: 'required',
                      props: {
                        items: (get('customFields.lead.category') || []).map(i => {
                          return {
                            title: i,
                            id: i,
                          }
                        }),
                      },
                    },
                  },
                ],
              },
            },
            data: {
              project: get('id', 'Root.item'),
              reference: { onModel: 'Project', ref: get('id', 'Root.item') },
            },
          },
        ],
      },
    },

    {
      component: 'Banner',
      condition: get('customFields.contact-cta.content', 'Root.item'),
      props: {
        class: 'contact-cta-bottom ',
        style: 'padding-block: 3.5rem;',
      },
      data: {
        backgroundImage: get('customFields.contact-cta.image.url', 'Root.item'),
      },

      blocks: {
        default: {
          component: 'Text',
          data: {
            content: get('customFields.contact-cta.content', 'Root.item'),
          },
          props: {
            textAlign: 'center',
          },
        },
      },
    },
  ]
}
