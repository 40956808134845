import { TransformTag } from '@/utils'

export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.main', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  return [
    {
      condition: get('media.cover.length'),
      component: 'Section',
      props: {
        class: 'hero',
        width: 'full',

        style: 'padding:0;',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            data: get('media.cover'),
            props: {
              aspectRatio: '16/5',
              class: 'hero__slider',
              options: {
                manualNavigation: false,
              },
            },
            blocks: {
              default: [
                {
                  component: 'Flex',
                  props: {
                    class: 'hero__layout',
                  },
                  blocks: {
                    default: {
                      component: 'Text',
                      props: {
                        class: 'hero__content',
                      },
                      data: {
                        content: `<h1>${get('title')}</h1>`,
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...(get('customFields.additional-sections') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          showResponsivePadding: true,
        },
      }
    }),

    {
      component: 'Section',
      props: {
        class: 'progress-section-1',
        theme: 'primary',
        style: '--section-padding-inline: 0rem',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              textAlign: 'center',
            },
            data: {
              content: get('customFields.progress-content'),
            },
          },
          {
            component: 'Progress',
            props: {
              theme: 'primary',
            },
            data: {
              items: get('customFields.progress'),
            },
          },
        ],
      },
    },

    ...(get('customFields.additional-sections-1') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          class: 'additional-sections-1-span-none',
          showResponsivePadding: true,
        },
      }
    }),

    {
      condition: () => {
        return (get('customFields.additional-icons') || []).filter(i => {
          if (!i) return false
          return i.content || i.icon
        }).length
      },
      component: 'Section',

      props: {
        class: 'features',
        width: 'medium',
        theme: get('customFields.additional-icons-settings.theme') || 'none',
      },

      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              columns: parseInt(get('customFields.additional-icons-settings.columns')) || 4,
              iconBackgroundColor: get(
                'customFields.additional-icons-settings.show-icon-background-color',
              )
                ? get('customFields.icons-settings.icon-background-color')
                : 'inherit',

              borderTheme: get('customFields.additional-icons-settings.border-theme'),
              placement: get('customFields.additional-icons-settings.placement'),
              padding: '0',
            },
            data: {
              content: get('customFields.additional-icons'),
            },
            variant: get('customFields.additional-icons-settings.variant') || 'none',
          },
        ],
      },
    },
    ...(get('customFields.additional-sections-2') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          class: '',
          showResponsivePadding: true,
        },
      }
    }),

    {
      component: 'Banner',
      condition: get('customFields.contact-cta.content', 'Root.item'),
      props: {
        class: 'contact-cta-bottom ',
        style: 'padding-block: 3.5rem;',
      },
      data: {
        backgroundImage: get('customFields.contact-cta.image.url', 'Root.item'),
      },

      blocks: {
        default: {
          component: 'Text',
          data: {
            content: get('customFields.contact-cta.content', 'Root.item'),
          },
          props: {
            textAlign: 'center',
          },
        },
      },
    },
  ]
}
