<template>
  <div class="progress" :class="[`progress-theme--${theme}`]">
    <div
      v-for="(item, index) in data.items"
      class="progress__container"
      :class="{ 'progress__container--active': item.finished }"
      :key="index"
    >
      <div class="progress__dot"></div>
      <div class="progress__content" v-html="item.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        items: [],
      }),
    },
    theme: {
      type: String,
      default: 'primary',
    },
  },
}
</script>

<style lang="scss">
// padding: 62px 0 68px;

.progress {
  &-theme {
    &--primary {
      color: $beige;
      background-color: var(--primary);

      .progress {
        &__dot {
          background: black;
        }

        &__container {
          &:before,
          &:after {
            background-color: black;
          }

          &--active {
            &:before,
            &:after {
              background-color: $beige;
            }

            .progress__dot {
              background-color: $beige;
              &:after {
                background-color: $beige;
              }
            }
          }
        }
      }
    }
    &--secondary {
    }
    &--default {
      color: var(--primary);
      background-color: inherit;
      .progress {
        &__dot {
          background: var(--primary);
        }

        &__container {
          &:before,
          &:after {
            background: var(--primary);
          }

          &--active {
            $orange: #ed7c74;

            &:before,
            &:after {
              background-color: $orange;
            }

            .progress__dot {
              background: $orange;
              &:after {
                background-color: $orange;
              }
            }
          }
        }
      }
    }
  }

  @keyframes breathe {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(2);
      transform: scale(2);
    }
    to {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
  padding: 80px 0 0;
  list-style: none;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  box-sizing: border-box;

  @include respond-below('phone') {
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
  }

  &__container:not(:first-child) {
    &:before {
      left: 0;
      right: 50%;
      content: '';
      position: absolute;
      top: 0;
      display: block;
      height: 2px;
    }

    @include respond-below('phone') {
      &:before {
        left: auto;
        right: 0;
        height: auto;
        width: 2px;
        top: 0;
        bottom: 50%;
      }
    }
  }

  &__container:not(:last-child) {
    &:after {
      left: 50%;
      right: 0;
      content: '';
      position: absolute;
      top: 0;
      display: block;
      height: 2px;
    }

    @include respond-below('phone') {
      &:after {
        top: 50%;
        bottom: 0;
        left: auto;
        right: 0;
        height: auto;
        width: 2px;
      }
    }
  }

  &__container {
    flex: 0 1 auto;
    position: relative;
    padding: 26px 0 0;

    text-align: center;
    width: 276px;

    @include respond-below('phone') {
      padding: 8px 16px 8px 8px;
      height: auto;
      min-height: 48px;
      width: 80%;
      max-width: 80%;
      text-align: left;
    }

    // &--active:not(:first-child) {
    //   &:before {

    //   }
    // }

    // &--active:not(:last-child) {
    //   &:after {
    //     background-color: $beige;
    //   }
    // }

    &--active {
      .progress__dot {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          //  background: $beige;
          opacity: 0.3;
          -webkit-animation-name: breathe;
          animation-name: breathe;
          -webkit-animation-duration: 2s;
          animation-duration: 2s;
          -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
          -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
        }
      }
    }
  }
  &__dot {
    left: calc(50% - 7px);

    width: 14px;
    height: 14px;
    border-radius: 7px;

    position: absolute;
    top: -6px;
    z-index: 1;

    @include respond-below('phone') {
      right: -6px;
      left: auto;
      top: calc(50% - 7px);
    }
  }
  &__content {
    strong {
      font-weight: 600;
    }

    p {
      margin: 0;
    }

    p:last-child {
      padding: 0 12px;
      font-weight: 400;
    }
  }
}
</style>
