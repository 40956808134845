import { TransformTag } from '@/utils'

export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.main', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  return [
    {
      condition: get('media.cover.length'),
      component: 'Section',
      props: {
        class: 'hero',
        width: 'full',
        style: 'padding:0;',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            data: get('media.cover'),
            props: {
              aspectRatio: '16/6.5',
              class: 'hero__slider',
              options: {
                manualNavigation: false,
              },
            },
            blocks: {
              default: [
                {
                  component: 'Flex',
                  props: {
                    class: 'hero__layout',
                  },
                  blocks: {
                    default: {
                      component: 'Text',
                      props: {
                        class: 'hero__content',
                      },
                      data: {
                        content: `<h1>${get('title')}</h1>`,
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        style: '--section-padding-inline: 0rem; padding-block: 1.5rem 4rem;',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              style: 'max-width: 40.5rem; margin: 0 auto; padding-inline: 1.5rem;',
            },
            data: {
              content: get('content'),
            },
          },
          {
            component: 'Flatfinder',
            condition: get('customFields.flatfinder.id'),
            props: {
              style: 'padding-top: 2rem',
              class: 'rental-flatfinder',
              customStatusFilter: ['!sold', '!rented'],
              preventFlatfinderNavigation: true,
            },
            data: {
              flatfinders: get('flatfinders', 'Root.item')
                .filter(flatfinder => flatfinder.id === get('customFields.flatfinder.id'))
                .map(flatfinder => {
                  return {
                    ...flatfinder,
                    name: get('customFields.flatfinder.title') || flatfinder.name,
                  }
                }),
            },
          },
        ],
      },
    },

    {
      component: 'Banner',
      condition: get('customFields.contact-cta.content', 'Root.item'),
      props: {
        class: 'contact-cta-bottom ',
        style: 'padding-block: 3.5rem;',
      },
      data: {
        backgroundImage: get('customFields.contact-cta.image.url', 'Root.item'),
      },

      blocks: {
        default: {
          component: 'Text',
          data: {
            content: get('customFields.contact-cta.content', 'Root.item'),
          },
          props: {
            textAlign: 'center',
          },
        },
      },
    },
  ]
}
