<template>
  <Loader
    theme="overlay"
    :value="promises.fetch"
    class="main-subpage"
    :class="[
      `page-${post.slug}`,
      {
        'main-subpage__rental': rental,
        'page-has-no-cover': !$path('media.cover.length', post),
      },
    ]"
    :key="$path('title', post)"
  >
    <Blocks v-if="item.posts" :blocks="config" :customComponents="customComponents" />
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'

// pagebuilder
import Config from '@/config/mainSubpage'
import { Blocks } from '@kvass/pagebuilder'

import Progress from '@/components/Progress.vue'
import ArticleCard from '@/components/ArticleCard.vue'
import CustomMap from '@/components/CustomMap'
import TravelCalculator from '@kvass/travel-calculator'
import { animate, inView } from 'motion'

export default {
  props: {
    main: {
      type: String,
      default: 'post',
    },
    slug: {
      type: String,
      default: 'post',
    },
  },
  computed: {
    ...mapState('Root', ['promises', 'item']),
    customComponents() {
      return { Progress, ArticleCard, CustomMap, TravelCalculator }
    },
    config() {
      const sources = this.post.customFieldsConfig?.map(schema => schema.source) || ['subpage']

      return Config[sources[0]].call(this)
    },
    rental() {
      if (!this.post.customFieldsConfig) return

      return this.$path('customFieldsConfig.0.source', this.post).startsWith('rental')
    },
    post() {
      if (!this.item.posts) return {}

      let post = this.item.posts.find(p => p.slug === this.main)
      const parent = this.item.posts.find(p => p.slug === this.slug)

      if (!post || !parent) {
        this.$router.push({ name: 'Project' })
      }

      if (this.main === 'aktuelt') {
        post = this.item.posts.find(
          p =>
            this.$path('customFieldsConfig.0.source', p) ===
            this.$path('customFieldsConfig.0.source', parent) + '-applicable',
        )
      }

      if (
        post.customFieldsConfig[0].source.startsWith('rental') &&
        parent.customFieldsConfig[0].source !== 'rental'
      ) {
        this.$router.push({ name: 'Project' })
      }
      if (
        post.customFieldsConfig[0].source.startsWith('about') &&
        parent.customFieldsConfig[0].source !== 'about'
      ) {
        this.$router.push({ name: 'Project' })
      }

      return post
    },
  },
  watch: {
    main: {
      handler(val) {
        return this.fetch(val)
          .then(data => {
            if (!data) return this.$router.push({ name: 'notfound' })
          })
          .then(() => this.$nextTick(() => this.initAnimation()))
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
    async initAnimation() {
      inView('.hero', () => {
        animate(
          '.hero',
          { opacity: 1, transform: 'none' },
          { duration: 0.4, delay: 0.2 },
          { easing: 'linear' },
        )
      })
      inView('.hero__content', () => {
        animate('.hero__content', { opacity: 1, transform: 'none' }, { duration: 2, delay: 0.4 })
      })

      const images = document.querySelectorAll('.kpb-gallery__item')

      if (images.length) {
        animate(
          images,
          { opacity: 1, transform: 'none' },
          { duration: 0.4, delay: 0.2 },
          { easing: 'linear' },
        )
      }

      const button = document.querySelectorAll('a[data-cta]')
      if (button.length) {
        animate(button, { opacity: 1, transform: 'none' }, { duration: 2, delay: 0.6 })
      }

      const avatar = document.querySelectorAll('.kpb-contact-list__avatar')
      if (avatar.length) {
        animate(
          avatar,
          { opacity: 1, transform: 'none' },
          { duration: 0.4, delay: 0.2 },
          { easing: 'linear' },
        )
      }

      const articleCards = document.querySelectorAll('.article-card__image')
      if (articleCards.length) {
        animate(
          articleCards,
          { opacity: 1, transform: 'none' },
          { duration: 0.4, delay: 0.2 },
          { easing: 'linear' },
        )
      }
    },
  },
  created() {
    this.fetch()
  },

  metaInfo() {
    return {
      title: this.$path('title', this.post),
    }
  },

  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.main-subpage {
  &__rental {
    background-color: var(--primary);
    color: $beige;
    .contact-cta-bottom {
      color: var(--primary);
    }
  }

  .travel-time {
    &__content {
      margin: 0 auto;
      max-width: 30rem;
    }
  }

  .hero {
    background-color: rgb(132, 123, 117);
    opacity: 0;
    position: relative;
    max-width: 100%;

    .kpb-slider__slider {
      max-height: calc(1335px * 0.4);
      min-height: calc(1335px * 0.4);
      max-width: 100%;

      @include respond-below('phone') {
        max-height: calc(844px * 0.4);
        min-height: calc(844px * 0.4);
      }
    }

    &__slider {
    }

    &__layout {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;

      align-items: flex-end !important;

      width: 100%;
      height: 100%;

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          color-stop(60%, rgba(0, 0, 0, 0.2)),
          to(transparent)
        );
        background: -o-linear-gradient(rgba(0, 0, 0, 0.2) 60%, transparent);
        background: linear-gradient(rgba(0, 0, 0, 0.2) 60%, transparent);
        pointer-events: none;
      }
    }

    &__content {
      background: radial-gradient(rgba(0, 0, 0, 0.5) 0, transparent 60%);

      opacity: 0;
      color: $beige;
      margin: 0 auto;
      padding: 2rem 1.5rem;
      width: 100%;
      max-width: 28.125rem;
      text-align: center;

      h1 {
        margin-bottom: 1.5rem;

        font-size: 2.3rem;
        @include respond-below('phone') {
          font-size: 1.84rem;
        }

        @include respond-below('tiny') {
          font-size: 1.61rem;
        }
      }
    }
  }

  .additional-sections-1-span-none {
    padding: 3rem 0 3rem 0;
    @include respond-below('phone') {
      padding: 2rem 0 2rem 0;
    }
    .kpb-images-text-section__images {
      gap: 2.5rem !important;
      @include respond-below('phone') {
        gap: 1rem !important;
      }
    }
    .kpb-images-text-section__images--layout-none .kpb-gallery__item:nth-child(n + 3) {
      grid-column-end: unset;
    }
  }

  .travel-time {
    background-color: $beige;
    color: var(--primary);
  }

  .custom-map {
    background-color: $beige;
    padding: 5% 15% 0;

    @include respond-below('phone') {
      padding: 0;
      padding-top: 2rem;
    }
  }

  .key-value {
    .kpb-stats {
      &__content {
        max-width: 60rem;
        padding: 0;
        gap: 55px 0;
      }

      &__item {
        width: calc(100% / 3);

        @include respond-below('tiny') {
          width: calc(100% / 2);
        }
      }
      &__value {
        font-weight: normal;
        font-family: var(--secondary-font);
        font-size: 1.6875rem;
        margin: 0 0 0.5rem;

        @include respond-below('phone') {
          font-size: 1.35rem;
        }
        @include respond-below('tiny') {
          font-size: 1.18125rem;
        }

        &:after {
          display: none;
        }
      }
      &__label {
        font-size: 1.15rem;

        @include respond-below('tablet') {
          font-size: 1.1rem;
        }
        @include respond-below('phone') {
          font-size: 1rem;
        }
      }
    }
  }

  .rental-flatfinder {
    .flatfinder-leaflet-v1 .leaflet-container {
      border-radius: 0;
      background-color: var(--primary);
    }
    .flatfinder__container {
      padding: 0 !important;
    }
    .flatfinder__header {
      margin-top: calc(20px + 1.5rem);
      margin-bottom: 20px;
    }
    .flatfinder__title {
      text-align: left;
      padding: 0 2vh;
      font-size: 20px;
      line-height: 25px;
      font-weight: normal;
    }
  }

  .additional-sections-center-button {
    &--big {
      .kpb-text {
        p:last-child {
          padding: 80px 0 30px 0;
          text-align: center;
        }
      }
    }

    .kpb-text {
      p:last-child {
        padding: 30px;
        text-align: center;
      }
    }
  }

  .features-rental-subpage-2 {
    p strong {
      @include respond-above('tablet') {
        font-size: 1.15rem;
      }

      @include respond-above('phone') {
        font-size: 1.1rem;
      }
    }
  }

  .features-rental-subpage {
    h2,
    h3 {
      font-weight: normal;
      margin: 0;
      margin-bottom: 0.5rem;
      font-size: 1.6875rem;

      @include respond-below('phone') {
        font-size: 1.35rem;
      }

      @include respond-below('tiny') {
        font-size: 1.18125rem;
      }
    }

    p {
      font-size: 16px;
    }
  }

  .press-room {
    .kpb-section__container {
      position: relative;
    }

    &__overlay {
      position: absolute;

      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      width: 100%;
      height: 100%;

      align-items: flex-end !important;
    }

    &__image {
      &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          color-stop(60%, rgba(0, 0, 0, 0.2)),
          to(transparent)
        );
        background: -o-linear-gradient(rgba(0, 0, 0, 0.2) 60%, transparent);
        background: linear-gradient(rgba(0, 0, 0, 0.2) 60%, transparent);
        pointer-events: none;
      }

      &-height {
        min-height: 350px;
      }
    }
    &__content {
      width: 100%;
      position: relative;
      padding: 2rem 1.5rem;
      max-width: 30rem;
      margin: 0 auto;
      color: $beige;

      h2 {
        font-size: 1.4rem;
      }

      h2,
      p {
        max-width: 28.125rem;
      }

      h2 {
        margin-bottom: 1.5rem;
      }

      @include respond-above('phone') {
        max-width: 45rem;
        h2 {
          font-size: 1.6rem;
        }
      }
      @include respond-above('tablet') {
        max-width: 60rem;

        h2 {
          font-size: 2rem;
        }
        p {
          margin-bottom: 1rem;
        }
      }
      @include respond-above('desktop') {
        max-width: 71.25rem;

        h2 {
          font-size: 2.15rem;
        }
        p {
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  .features {
    &__gap {
      @include respond-above('phone') {
        gap: 7rem 0 !important;
      }
    }

    .kpb-section__container {
      max-width: 60rem;
    }

    --section-padding-inline: 0;

    padding-block: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;

    @include respond-below('phone') {
      padding: 20px 0.5rem;
    }
  }

  .contact-icon {
    max-width: 30rem;
    margin: 0 auto;

    @include respond-above('phone') {
      max-width: 45rem;
    }
    @include respond-above('tablet') {
      max-width: 60rem;
    }
    @include respond-above('desktop') {
      max-width: 71.25rem;
    }

    padding: 2rem 1.5rem;
    padding-bottom: 0;

    margin-bottom: -0.5rem;

    .kpb-image__image {
      background-color: inherit;
      max-height: 35px;
      object-fit: contain;
      object-position: left;
    }
    &--big {
      .kpb-image__image {
        max-height: 60px;
      }
    }
  }

  .contact-content {
    max-width: 30rem;
    margin: 0 auto;

    @include respond-above('phone') {
      max-width: 45rem;
    }
    @include respond-above('tablet') {
      max-width: 60rem;
    }
    @include respond-above('desktop') {
      max-width: 71.25rem;
    }

    padding: 2rem 1.5rem;
    padding-top: 0;
    margin-bottom: 3vh;
  }

  .contact-section-empty {
    padding-block: 2.5rem;

    @include respond-below('tablet') {
      padding-block: 0rem;
    }
  }

  .contact-section {
    padding-block: calc(120px + 5.84416% + 5.5rem) 3.5rem;

    @include respond-below('tablet') {
      padding-block: calc(5.84416% + 5.5rem) 3.5rem;
    }
  }

  .contact-section-1 {
    padding-block: calc(120px + 3rem) 3rem;
  }

  .contact-section-2 {
    padding-block: 250px 10px;
    @include respond-below('phone') {
      padding-block: 100px 10px;
    }
  }

  .article-cards-section {
    padding-block: 0rem 5%;
    --section-width: 1000px;
    --section-padding-inline: 0;
  }

  .progress-section-1 {
    padding: 20px 0 20px;
  }

  .progress-section {
    padding: 62px 0 calc(68px + 5.63928%);
  }

  .progress__title {
    h2:first-child {
      margin-top: 1.5rem;
    }
  }

  .kpb-images-text-section.kpb-section--width-full {
    padding: 1.5rem 0 3rem 0;
  }

  .additional-sections {
    .kpb-images-text-section__images {
      gap: 0 !important;
      img {
        background-color: white;
      }
    }
  }

  .kpb-images-text-section {
    &__images {
    }

    &__content {
      @include respond-below('phone') {
        padding: 1.5rem !important;
      }

      &-size--small {
        max-width: 37.5rem;
      }
    }
  }
}

.pswp__img {
  background-color: white;
}

.about-subpage-lead-section {
  background-color: $beige;
  color: var(--primary);
}

.about-subpage-lead {
  .lead__form {
    background-color: inherit;
    color: var(--primary);
    grid-gap: 3rem;
    .custom-fields {
      grid-gap: 3rem;
      font-size: 0.8rem;
      font-weight: 500;

      border-radius: 0;
    }

    --vue-elder-border-radius: 0;

    --vue-elder-text-color: var(--primary);

    --vue-elder-border-color: transparent;

    ::placeholder {
      color: var(--primary);
    }

    :-ms-input-placeholder {
      color: var(--primary);
    }

    ::-ms-input-placeholder {
      color: var(--primary);
    }

    &-actions {
      margin-top: 0;

      .elder-button {
        color: var(--primary);
        border-color: var(--secondary-border, var(--secondary));
        background-color: var(--secondary);
        padding: var(--vue-elder-button-padding-y, 0.5rem) var(--vue-elder-button-padding-x, 1rem);
        border-radius: var(--border-radius, 3px);
        text-decoration: none;
        display: inline-block;
        min-width: 200px;
        transition: background-color 0.2s, color 0.3s;
        text-align: center;
        font-size: 1rem;

        @media (min-width: 768px) {
          font-size: 1.1rem;
        }

        &__icon {
          display: none;
        }

        &:before {
          display: none;
        }

        &:hover {
          background-color: rgb(255, 254, 97);
        }

        &:after {
          margin-left: 10px;
          width: 30px;
          height: 12px;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.463' height='11.898' viewBox='0 0 29.463 11.898'%3E%3Cg id='Group_146' data-name='Group 146' transform='translate(22650.453 73.556)'%3E%3Cline id='Line_1' data-name='Line 1' x2='27.047' transform='translate(-22649.453 -67.606)' fill='none' stroke='%233f3a39' stroke-linecap='round' stroke-width='2'%3E%3C/line%3E%3Cpath id='Path_55' data-name='Path 55' d='M-22541.605-60.5l-4.535,4.535' transform='translate(-80.8 -7.107)' fill='none' stroke='%233f3a39' stroke-linecap='round' stroke-width='2'%3E%3C/path%3E%3Cpath id='Path_56' data-name='Path 56' d='M-22541.605-55.965l-4.535-4.535' transform='translate(-80.8 -11.642)' fill='none' stroke='%233f3a39' stroke-linecap='round' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
        }
      }
    }

    .elder-checkbox__label {
      font-weight: 500;
      a {
        color: var(--primary);
      }
    }

    .elder-checkbox__box {
      --border-radius: 0;
      background-color: inherit;
      border: 2px solid var(--primary);
    }

    .elder-input {
      &__label {
        display: none;
      }
      &__field {
        background-color: inherit;
        border: none;
        border-bottom: 1px solid var(--primary);

        &--focus {
          border-bottom: 2px solid var(--primary);
        }
      }
      &__element {
        padding: 0.5em;
      }
    }

    .custom-field--selectlist .elder-input {
      &__label {
        display: initial;
      }

      &__field {
        background-color: inherit;

        border: 1px solid var(--primary);

        &--focus {
          border: none;
          border-radius: 0;
        }
        .field-select__element {
          border-radius: 0;
        }
      }
    }
  }
}
</style>
