var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Loader',{key:_vm.$path('title', _vm.post),staticClass:"main-subpage",class:[
    `page-${_vm.post.slug}`,
    {
      'main-subpage__rental': _vm.rental,
      'page-has-no-cover': !_vm.$path('media.cover.length', _vm.post),
    },
  ],attrs:{"theme":"overlay","value":_vm.promises.fetch}},[(_vm.item.posts)?_c('Blocks',{attrs:{"blocks":_vm.config,"customComponents":_vm.customComponents}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }