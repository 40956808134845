import { TransformTag } from '@/utils'

export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.main', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  return [
    {
      condition: get('media.cover.length'),
      component: 'Section',
      props: {
        class: 'hero',
        width: 'full',

        style: 'padding:0;',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            data: get('media.cover'),
            props: {
              aspectRatio: '16/6.5',
              class: 'hero__slider',
              options: {
                manualNavigation: false,
              },
            },
            blocks: {
              default: [
                {
                  component: 'Flex',
                  props: {
                    class: 'hero__layout',
                  },
                  blocks: {
                    default: {
                      component: 'Text',
                      props: {
                        class: 'hero__content',
                      },
                      data: {
                        content: `<h1>${get('title')}</h1>`,
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...(get('customFields.additional-sections') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          showResponsivePadding: true,
        },
      }
    }),

    {
      component: 'Section',
      props: {
        width: 'large',
        style: '--section-width: 60rem',
        theme: 'primary',
      },
      blocks: {
        default: {
          component: 'Stats',
          props: {
            padding: '2rem',
            theme: 'primary',
            class: 'key-value',
            width: 'full',
            postion: 'center',
          },
          variant: 'solid',
          data: {
            content: get('customFields.key-info'),
          },
        },
      },
    },

    {
      component: 'Section',
      condition: get('address.location.coordinates', 'Root.item'),
      props: {
        width: 'full',
        class: 'custom-map custom-map-location',
      },
      blocks: {
        default: {
          component: 'CustomMap',
          props: {
            style: 'min-height: 450px; max-width: 100%;',
            class: '',
            showControls: true,
            scrollZoom: false,
            multipleStyles: [
              {
                style: process.env.VUE_APP_MAPBOX_STYLE,
                name: 'Kart',
              },
              {
                style: process.env.VUE_APP_MAPBOX_STYLE_SATELLITE,
                name: 'Satellitt',
              },
            ],
          },
          data: {
            coordinates: get('address.location.coordinates', 'Root.item'),
          },
        },
      },
    },

    {
      component: 'Section',
      condition: get('address.location.coordinates', 'Root.item'),
      props: {
        width: 'small',
        class: 'travel-time',
      },
      blocks: {
        default: [
          {
            component: 'TravelCalculator',
            props: {
              icons: {
                'driving-traffic': ['far', 'hashtag'],
                cycling: ['fal', 'bicycle'],
              },
              buttonOptions: {
                theme: 'secondary',
              },
            },
            data: {
              coordinates: get('address.location.coordinates', 'Root.item'),
            },
            blocks: {
              default: {
                component: 'Text',
                condition: get('customFields.travel-time-content'),
                props: {
                  class: 'travel-time__content',
                },
                data: {
                  content: get('customFields.travel-time-content'),
                },
              },
            },
          },
        ],
      },
    },

    {
      component: 'Banner',
      condition: get('customFields.contact-cta.content', 'Root.item'),
      props: {
        class: 'contact-cta-bottom ',
        style: 'padding-block: 3.5rem;',
      },
      data: {
        backgroundImage: get('customFields.contact-cta.image.url', 'Root.item'),
      },

      blocks: {
        default: {
          component: 'Text',
          data: {
            content: get('customFields.contact-cta.content', 'Root.item'),
          },
          props: {
            textAlign: 'center',
          },
        },
      },
    },
  ]
}
