import { TransformTag } from '@/utils'

export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.main', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  return [
    {
      condition: get('media.cover.length'),
      component: 'Section',
      props: {
        class: 'hero',
        width: 'full',

        style: 'padding:0;',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            data: get('media.cover'),
            props: {
              aspectRatio: '16/6.5',
              class: 'hero__slider',
              options: {
                manualNavigation: false,
              },
            },
            blocks: {
              default: [
                {
                  component: 'Flex',
                  props: {
                    class: 'hero__layout',
                  },
                  blocks: {
                    default: {
                      component: 'Text',
                      props: {
                        class: 'hero__content',
                      },
                      data: {
                        content: `<h1>${get('title')}</h1>`,
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        style: '--section-padding-inline: 0rem; padding-block: 1.5rem 4rem;',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              style: 'max-width: 40.5rem; margin: 0 auto; padding-inline: 1.5rem;',
            },
            data: {
              content: get('content'),
            },
          },
          {
            component: 'Flatfinder',
            props: {
              style: 'padding-top: 2rem',
              class: 'rental-flatfinder',
              preventFlatfinderNavigation: true,
            },
            data: {
              flatfinders: get('flatfinders', 'Root.item')
                .filter(flatfinder => flatfinder.id === get('customFields.flatfinder.id'))
                .map(flatfinder => {
                  return {
                    ...flatfinder,
                    name: get('customFields.flatfinder.title') || flatfinder.name,
                  }
                }),
            },
          },
        ],
      },
    },

    ...(get('customFields.additional-sections') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          class: 'additional-sections-center-button--big',
          showResponsivePadding: true,
        },
      }
    }),

    {
      condition: () => {
        return (get('customFields.icons') || []).filter(i => {
          if (!i) return false
          return i.content || i.icon
        }).length
      },
      component: 'Section',

      props: {
        width: 'medium',
        theme: get('customFields.icons-settings.theme') || 'none',
        class: 'features',
      },

      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              class: 'features-rental-subpage',
              columns: parseInt(get('customFields.icons-settings.columns')) || 4,
              iconBackgroundColor: get('customFields.icons-settings.show-icon-background-color')
                ? get('customFields.icons-settings.icon-background-color')
                : 'inherit',

              borderTheme: get('customFields.icons-settings.border-theme'),
              placement: get('customFields.icons-settings.placement'),
              padding: '0',
            },
            data: {
              content: get('customFields.icons'),
            },
            variant: get('customFields.icons-settings.variant') || 'none',
          },
        ],
      },
    },
    ...(get('customFields.additional-sections-1') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          class: 'additional-sections-center-button',
          showResponsivePadding: true,
        },
      }
    }),

    {
      condition: () => {
        return (get('customFields.additional-icons') || []).filter(i => {
          if (!i) return false
          return i.content || i.icon
        }).length
      },
      component: 'Section',

      props: {
        width: 'medium',
        theme: get('customFields.additional-icons-settings.theme') || 'none',
        class: 'features',
      },

      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              class: 'features-rental-subpage-2 features__gap',
              columns: parseInt(get('customFields.additional-icons-settings.columns')) || 4,
              iconBackgroundColor: get(
                'customFields.additional-icons-settings.show-icon-background-color',
              )
                ? get('customFields.icons-settings.icon-background-color')
                : 'inherit',

              borderTheme: get('customFields.additional-icons-settings.border-theme'),
              placement: get('customFields.additional-icons-settings.placement'),
              padding: '0',
            },
            data: {
              content: get('customFields.additional-icons'),
            },
            variant: get('customFields.additional-icons-settings.variant') || 'none',
          },
        ],
      },
    },

    ...(get('customFields.additional-sections-2') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          class: '',
          showResponsivePadding: true,
        },
      }
    }),

    {
      component: 'Section',
      props: {
        class: 'press-room',
        width: 'full',
        style: 'padding-block: 2% 5%;',
      },
      blocks: {
        default: [
          {
            component: 'Image',
            props: {
              class: 'press-room__image press-room__image-height',
            },
            data: {
              image: get('customFields.press-room.image'),
            },
          },
          {
            component: 'Flex',
            props: {
              class: 'press-room__overlay',
            },
            blocks: {
              default: {
                component: 'Text',
                props: {
                  class: 'press-room__content',
                },
                data: {
                  content: get('customFields.press-room.content'),
                },
              },
            },
          },
        ],
      },
    },

    {
      component: 'Banner',
      condition: get('customFields.contact-cta.content', 'Root.item'),
      props: {
        class: 'contact-cta-bottom ',
        style: 'padding-block: 3.5rem;',
      },
      data: {
        backgroundImage: get('customFields.contact-cta.image.url', 'Root.item'),
      },

      blocks: {
        default: {
          component: 'Text',
          data: {
            content: get('customFields.contact-cta.content', 'Root.item'),
          },
          props: {
            textAlign: 'center',
          },
        },
      },
    },
  ]
}
