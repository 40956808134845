import { TransformTag } from '@/utils'

export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.main', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  const post = (this.$path('posts', this.item) || []).find(
    post => this.$path('slug', post) === this.$path('_props.slug', this),
  )

  const postCustomFieldsConfig = this.$path('customFieldsConfig.0.source', post)

  const mainPost = (this.$path('posts', this.item) || []).find(
    post =>
      this.$path('customFieldsConfig.0.source', post) === postCustomFieldsConfig + '-applicable',
  )

  const articles = (this.$path('posts', this.item) || [])
    .filter(post => this.$path('customFieldsConfig.0.source', post) === 'article')
    .filter(article => {
      const type = this.$path('customFields.site-settings.type', article)
      if (!type) return false
      return type.includes(postCustomFieldsConfig)
    })
    .sort(
      (a, b) =>
        this.$path('customFields.site-settings.order', a) -
        this.$path('customFields.site-settings.order', b),
    )

  const getArticleUrlPath = () => {
    if (!post || !mainPost) return

    return `/${post.slug}/${mainPost.slug}`
  }

  return [
    {
      condition: get('media.cover.length'),
      component: 'Section',
      props: {
        class: 'hero',
        width: 'full',

        style: 'padding:0;',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            data: get('media.cover'),
            props: {
              aspectRatio: '16/6.5',
              class: 'hero__slider',
              options: {
                manualNavigation: false,
              },
            },
            blocks: {
              default: [
                {
                  component: 'Flex',
                  props: {
                    class: 'hero__layout',
                  },
                  blocks: {
                    default: {
                      component: 'Text',
                      props: {
                        class: 'hero__content',
                      },
                      data: {
                        content: `<h1>${get('title')}</h1>`,
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        class: 'article-cards-section',
      },
      blocks: {
        default: {
          component: 'ArticleCard',
          props: {},
          data: {
            articles: articles,
            path: getArticleUrlPath(),
          },
        },
      },
    },

    {
      component: 'Banner',
      condition: get('customFields.contact-cta.content', 'Root.item'),
      props: {
        class: 'contact-cta-bottom ',
        style: 'padding-block: 3.5rem;',
      },
      data: {
        backgroundImage: get('customFields.contact-cta.image.url', 'Root.item'),
      },

      blocks: {
        default: {
          component: 'Text',
          data: {
            content: get('customFields.contact-cta.content', 'Root.item'),
          },
          props: {
            textAlign: 'center',
          },
        },
      },
    },
  ]
}
